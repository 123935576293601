<template>
   <div class="searchwrap" v-if="!$route.meta.layout || $route.meta.layout !== 'popup'">
     <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.memberID') }}</span></div>
          <div>
             <input type="text" class="mr-5" v-model="reqData.memId" />
             <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
             </button>
          </div>
        </div>
     </div>
   </div>
  <div class="main-contents-wrapper" id="top">
     <div class="tab-wrap">
       <button type="button" @click="onChangeType('userdetail')" class="tab-item" :class="{'active': type === 'userdetail'}">{{ $t('common.memberDe') }}</button>
       <!--button type="button" @click="onChangeType('gamerate')" class="tab-item" :class="{'active': type === 'gamerate'}">{{ $t('user.rollByGame') }}</button-->
       <button type="button" @click="onChangeType('betsetting')" class="tab-item" :class="{'active': type === 'betsetting'}">{{ $t('common.bettingSettingByGame') }}</button>
       <button type="button" @click="onChangeType('rateSet')" class="tab-item" :class="{'active': type === 'rateSet'}">{{ $t('common.rateSet') }}</button>
     </div>
     <template v-if="userData">
      <div class="allwrap" v-if="type == 'userdetail'">
        <div>
          <article class="content_wrap all">
             <div>

               <table>
                 <colgroup>
                   <col width="18%">
                   <col width="32%">
                   <col width="18%">
                   <col width="32%">
                 </colgroup>
                 <thead>
                   <tr>
                     <th colspan="3" class="left">
                        {{ $t('common.user_info') }}
                        <span class="userName">
                           {{ userData.memId }} (<em>{{ userData.memNick }}</em>)
                        </span>
                     </th>
                     <th colspan="1" class="flexEnd">
                       <div class="saveBtnWrap">
                         <!-- <button class="btn-save user-btn-bg chasnge-partner" @click="changePartner" v-if="userData.upPartnerLevel !== 'PTN_5'">{{ $t('button.changePartner') }}</button> -->
                         <button class="btn-save user-btn-bg" @click="userInfoUpdate">{{ $t('button.save') }}</button>
                       </div>
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{ $t('common.id') }}</td>
                     <td>
                       <div class="fx-col-sbw">
                          <div class="fx vt-c gap-5">
                            {{ userData.memId }}
                            <span class="levelbox" ><span :class="userData.partnerLevel">{{computedPartnerLevel(userData)}}</span></span>
                          </div>
                          <button class="btn-layout btn-point" type="button" @click="_memLogout(userData.memId)">{{ $t('table.head.block') }}</button>
                       </div>
                     </td>
                     <td>{{ $t('common.nickName') }}</td>
                     <td>
                       <input type="text" v-model="userData.memNick" />
                     </td>
                   </tr>
                   <tr>
                      <td>{{ $t('common.password') }}</td> <!--초기화-->
                      <td>
                        <button class="btn-layout btn-point mr-5" @click="resetPassword">{{ $t('button.reset') }}</button>
                        <em class="pw">{{ $t('txt.resetPW') }} (0000)</em>
                      </td>
                      <td>{{ $t('common.cashPass') }}</td>
                      <td>
                        <input type="text" v-model="userData.cashOutPass" />
                      </td>
                   </tr>
                   <tr>
                      <td>{{ $t('common.name') }}</td>
                      <td>
                        <input type="text" v-model="userData.memName" />
                      </td>
                      <td>{{ $t('table.head.accountNumber') }}</td>
                      <td>
                        <div class="fx gap-5">
                           <select v-model="userData.bank">
                              <option v-if="!userData.bank || !bankList" value="">{{ $t('common.noinfobank') }}</option>
                              <template v-for="item in bankList" :key="item.bankCode">
                                 <option :value="item.bankCode">{{ item.bankNameKr }}</option>
                              </template>
                           </select>
                           <input type="text" v-model="userData.bankacc" />
                        </div>
                      </td>
                   </tr>
                   <tr>
                      <td>{{ $t('company.contact') }}</td>
                      <td>
                        <input type="text" v-model="userData.memPhone" />
                      </td>
                      <td>{{ $t('common.memLevel') }}</td>
                      <td>
                        <select class="userInfo-select" v-model="userData.memLevel">
                           <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                   <tr>
                      <td>{{ $t('cash.totalBalance') }}</td>
                      <td>
                        <div class="inputBtnWrap widthSet">
                           <span>{{ numberWithCommas(userData.cashAmt) }}</span>
                           <a class="btn-inout" @click="cashModalOpen('cash')">{{ $t('searchArea.listType') }}</a>
                           <button class="btn-layout btn-point" @click="moneyMerge">{{ $t('button.changeMoney') }}</button>
                        </div>
                      </td>
                      <td>{{ $t('user.regDate') }}</td>
                      <td>
                        <span>{{ userData.redDt }}</span>
                      </td>
                   </tr>
                   <tr>
                      <td>{{ $t('table.head.pointAmt') }}</td>
                      <td>
                        <div class="inputBtnWrap">
                           <span>{{ numberWithCommas(userData.pointAmt) }}</span>
                           <a class="btn-inout" @click="cashModalOpen('point')">{{ $t('searchArea.listType') }}</a>
                        </div>
                      </td>
                      <td>{{ $t('table.head.lastLogin') }}</td>
                      <td>
                        <span>{{ userData.lastLoginDt }}</span>
                      </td>
                   </tr>
                   <tr>
                      <td>{{ $t('searchArea.highuser') }}</td>
                      <td>
                        <button type="button" class="btn-link" @click="detailOpen('partner', userData.recommenderId)">{{ userData.recommenderId }}</button>
                      </td>
                      <td>{{ $t('table.head.lastLogin') }}  {{ $t('table.head.ip') }}</td>
                      <td>
                        <span>{{ userData.lastIp }}</span>
                      </td>
                   </tr>
                   <tr>
                     <td colspan="1">{{ $t('common.supPartnerList') }}</td>
                     <td colspan="3">
                       <div class="supComp-list-wrap">
                         <template v-for="(item, idx) in memberFlow" :key="item.recommenderId">
                           <button v-if="userData.memId !== item.recommenderId" type="button" :class="`compColor${idx} btn-link`" @click="detailOpen('partner', item.recommenderId)">{{ item.recommenderId }}</button>
                           <span v-if="item.siteId !== item.recommenderId && userData.memId === item.recommenderId" class="compColor-target">
                           {{ item.recommenderId }}
                         </span>
                         </template>
                       </div>
                     </td>
                   </tr>
                 </tbody>
               </table>

               <div class="button-wrap">
                 <button class="user-btn-bg" type="button" data-link="betList" @click="parentLinkMove('/bet/list/all', userData.memId)">{{ $t('betting.totalBetCount') }}</button>
                 <button class="user-btn-bg" type="button" data-link="deposit" @click="parentLinkMove('/user/cash/in/report', userData.memId)">{{ $t('common.depH') }}</button>
                 <button class="user-btn-bg" type="button" data-link="withdraw" @click="parentLinkMove('/user/cash/out/report', userData.memId)">{{ $t('common.WithH') }}</button>
                 <button class="user-btn-bg" type="button" data-link="point" @click="parentLinkMove('/user/pointList', userData.memId)">{{ $t('common.pointH') }}</button>
                 <button class="user-btn-bg" type="button" data-link="" @click="parentLinkMove('/user/cash/cashMoveHistory', userData.memId)">{{ $t('common.cashH') }}</button>
                 <!-- <button class="user-btn-bg ft-14" type="button" data-link="" @click="parentLinkMovePopup('/user/cash/cashMoveHistory', userData.memId)">{{ $t('common.cashH') }}</button> -->
                 <button class="user-btn-bg" type="button" data-link="connect" @click="parentLinkMove('/user/userTracking', userData.memId)">{{ $t('common.accLog') }}</button>
                 <button class="user-btn-bg" type="button" @click="msgOpen = true">{{ $t('common.sendMsg') }}</button>
                 <button class="user-btn-bg" type="button" @click="parentLinkMove('/board/message', userData.memId)">{{ $t('common.MsgH') }}</button>
               </div>

               <div class="part-wrap">
                  <div class="part-box">
                     <table>
                       <colgroup>
                         <col width="35%">
                         <col width="20%">
                         <col width="25%">
                         <col width="20%">
                       </colgroup>
                       <thead>
                         <tr>
                           <th colspan="3" class="left">{{ $t('user.funcSet') }}</th>
                           <th class="flexEnd">
                             <div class="saveBtnWrap">
                               <button class="btn-save user-btn-bg" @click="userSettingUpdate">{{ $t('button.save') }}</button>
                             </div>
                           </th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td>{{ $t('user.isLogin') }}</td>
                           <td colspan="3">
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.loginYn" />
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('common.actLock') }}</td>
                           <td colspan="3">
                              <div class="fx-col-sbw">
                                 <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.memLockYn" />
                                 <button class="btn-layout user-btn-bl" @click="lockBotMemberSetting">{{ $t('button.allsavesub') }}</button>
                              </div>
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('common.setBlack') }}</td>
                           <td colspan="3">
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.memBlackYn" />
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('table.head.betYn') }}</td>
                           <td colspan="3">
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.betYn" />
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('user.abLock') }}</td>
                           <td colspan="3" class="flexEnd">
                            <div class="fx-col-sbw">
                              <span>{{ thousand(userData.abuseLockCnt) }} {{ $t('user.time') }}</span>
                              <span v-if="userData.abuseLock">[{{userData.abuseLock}}]</span>
                              <button class="btn-layout user-btn-bl" v-if="userData.abuseLockCnt" @click="onClickAbuseInit(userData)">{{ $t('button.reset') }}</button>
                            </div>
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('common.actReqYN') }}</td>
                           <td>
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.accInfoRequestYn" />
                           </td>
                           <td>{{ $t('searchArea.pointEx') }}</td>
                           <td>
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.pointExchangeYn" />
                           </td>
                         </tr>
                         <tr>
                           <td>{{ $t('user.userRecommend') }}</td>
                           <td colspan="3">
                             <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.recommendYn"/>
                           </td>
                         </tr>
                       </tbody>
                     </table>
                  </div>
                  <div class="part-box">
                     <div class="part-in">
                        <table>
                          <colgroup>
                            <col width="36%">
                            <col width="64%">
                          </colgroup>
                          <thead>
                            <tr>
                              <th class="left">{{ $t('common.idlingSet') }}</th>
                              <th class="flexEnd">
                                <div class="saveBtnWrap">
                                  <button class="btn-layout user-btn-bl" @click="blankBotMemberSetting">{{ $t('button.allsavesub') }}</button>
                                  <button class="btn-save user-btn-bg" @click="userSettingUpdateBlank">{{ $t('button.save') }}</button>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                             <tr>
                              <td>{{ $t('common.idling') }}({{ $t('table.head.casino') }})</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="checkbox" class="set-switch" @click="switchToggle" v-model="isBlankSpinCasinoRate" />
                                  <input type="text" class="w55" v-model="userData.blankSpinCasinoRate" onkeypress="return /^-?[0-9]*$/.test(this.value+event.key)">%
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('common.idling') }}({{ $t('table.head.slot') }})</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="checkbox" class="set-switch" @click="switchToggle" v-model="isBlankSpinSlotRate" />
                                  <input type="text" class="w55" v-model="userData.blankSpinSlotRate" onkeypress="return /^-?[0-9]*$/.test(this.value+event.key)">%
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <tr>
                              <th class="left" colspan="2">{{ $t('common.manageBonus') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t('common.firstChargeSU') }}</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="number" class="w55" v-model="userBonus.fstChrgByJoinRate"> <span>%</span>
                                  <button class="btn-save btn-red btn-layout" @click="setUserBonus('fstChrgByJoinRate')">{{ $t('button.save') }}</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('common.firstChargeDay') }}</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="number" class="w55" v-model="userBonus.fstChrgByDailyRate"> <span>%</span>
                                  <button class="btn-save btn-red btn-layout" @click="setUserBonus('fstChrgByDailyRate')">{{ $t('button.save') }}</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('common.ateveryCharge') }}</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="number" class="w55" v-model="userBonus.fstChrgByEveryTimeRate"> <span>%</span>
                                  <button class="btn-save btn-red btn-layout" @click="setUserBonus('fstChrgByEveryTimeRate')">{{ $t('button.save') }}</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('common.maxpay') }}</td>
                              <td>
                                <div class="inputBtnWrap">
                                  <input type="text"
                                         v-model="userBonus.bonusLimit"
                                  >
                                  <span>{{ $t('common.won') }}</span>
                                  <button class="btn-save btn-red btn-layout" @click="setUserBonus('bonusLimit')">{{ $t('button.save') }}</button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     </div>
                  </div>
               </div>

             </div>

             <div>
                <!--div class="button-wrap">
                  <button class="user-btn-bg" type="button" @click="_memLogout(userData.memId)">{{ $t('table.head.block') }}</button>
                  <button class="user-btn-bg" type="button" @click="cashModalOpen('point_in')">{{ $t('common.givepoint') }}</button>
                  <button class="user-btn-bg" type="button" @click="cashModalOpen('point_out')">{{ $t('common.repoint') }}</button>
                  <button class="user-btn-bg" type="button" @click="cashModalOpen('cash_in')">{{ $t('common.payReserve') }}</button>
                  <button class="user-btn-bg" type="button" @click="cashModalOpen('cash_out')">{{ $t('common.reReserve') }}</button>
                </div-->

                <table>
                 <colgroup>
                   <col width="35%">
                   <col width="65%">
                 </colgroup>
                 <thead>
                   <tr>
                     <th colspan="2" class="left">{{ $t('user.userAct') }} ({{ $t('txt.sumData') }})</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{ $t('table.head.totalCashInAmt') }}</td>
                     <td>{{ userData.totalCashInAmt }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.totalCashOutAmt') }}</td>
                     <td>{{ userData.totalCashOutAmt }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('betting.totalBetAmt') }}</td>
                     <td>{{ numberWithCommas(userData.totalBetAmt) }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('betting.totalwinAmt') }}</td>
                     <td>{{ numberWithCommas(userData.totalBetWinAmt) }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.totalWlAmt') }}</td>
                     <td>{{ userData.totalWlAmt }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('common.days3points') }}</td>
                     <td>{{ userData.totalChangePointAmt }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('common.totalLogin') }}</td>
                     <td>{{ userData.totalLoginCount }}</td>
                   </tr>
                   <tr>
                     <td>{{ $t('common.firstBonusYN') }}</td>
                     <td>
                       <span>
                         <template v-if="userBonus.fstChrgByJoinFlag === 0">
                           N
                         </template>
                         <template v-else>
                           Y
                         </template>
                       </span>
                     </td>
                   </tr>
                 </tbody>
               </table>

               <table>
                 <colgroup>
                   <col width="35%">
                   <col width="65%">
                 </colgroup>
                 <thead>
                   <tr>
                     <th class="left">{{ $t('company.classset') }}</th>
                     <th class="flexEnd">
                       <div class="saveBtnWrap">
                         <button class="btn-save user-btn-bg">{{ $t('button.save') }}</button>
                       </div>
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{ $t('table.head.betLevel') }}</td>
                     <td>
                        <select class="userInfo-select" v-model="userData.betLevel">
                           <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.pointLevel') }}</td>
                     <td>
                        <select class="userInfo-select" v-model="userData.pointLevel">
                           <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.bankLevel') }}</td>
                     <td>
                        <select class="userInfo-select" v-model="userData.bankLevel">
                           <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                 </tbody>
               </table>

               <!--table>
                 <colgroup>
                   <col width="35%">
                   <col width="65%">
                 </colgroup>
                 <thead>
                   <tr>
                     <th class="left">{{ $t('user.gameLevel') }}</th>
                     <th class="flexEnd">
                       <div class="saveBtnWrap">
                         <button class="btn-save user-btn-bg" @click="userGLevelUpdate">{{ $t('button.save') }}</button>
                       </div>
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{ $t('table.head.casino') }}vip{{ $t('common.level') }}</td>
                     <td>
                       <select class="userInfo-select" v-model="userData.casinoLevel">
                          <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.slot') }}vip{{ $t('common.level') }}</td>
                     <td>
                       <select class="userInfo-select" v-model="userData.slotLevel">
                          <option v-for="idx in levels" :key="idx.code" :value="idx.code">{{ idx.code }}</option>
                        </select>
                     </td>
                   </tr>
                 </tbody>
               </table-->

               <table v-if="isInsured"> <!---->
                 <colgroup>
                   <col width="35%">
                   <col width="65%">
                 </colgroup>
                 <thead>
                   <tr>
                     <th class="left">{{ $t('user.insSet') }}</th>
                     <th class="flexEnd">
                       <div class="saveBtnWrap">
                         <button class="btn-save user-btn-bg" @click="userInfoUpdate">{{ $t('button.save') }}</button>
                       </div>
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{ $t('user.insYN') }}</td>
                     <td>
                       <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.insuredYn" />
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('table.head.insureApplyBetAmt') }}</td>
                     <td>
                       <input type="text" ref="baseInsureBetAmt" v-model="userData.baseInsureBetAmt" maxlength="17" />
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('common.passing') }}</td>
                     <td>
                       <div class="inputBtnWrap">
                         <input type="checkbox" class="set-switch" @click="switchToggle"/>
                         <em class="vspassing">{{ $t('txt.upLevel') }}</em>
                       </div>
                     </td>
                   </tr>
                   <tr>
                     <td>{{ $t('common.press') }}</td>
                     <td>
                       <div class="inputBtnWrap">
                         <input type="checkbox" class="set-switch" @click="switchToggle" v-model="userData.isBiPushYn" />
                         <!--button type="button" class="btn-layout btn-red" @click="onOpenInsurePush">{{ $t('common.detailSet') }}</button>
                         <button type="button" class="btn-layout btn-red" @click="biModalOpen">에볼루션</button>
                         <button type="button" class="btn-layout btn-red" @click="biModalsOpen">하이로우</button-->
                       </div>
                     </td>
                   </tr>
                 </tbody>
               </table>
             </div>

             <div>
                <table style="height:100%;">
                 <thead>
                   <tr>
                     <th class="left">{{ $t('table.head.memo') }}</th>
                     <th class="flexEnd">
                       <div class="saveBtnWrap">
                         <button class="btn-save user-btn-bg" @click="userMemoUpdate">{{ $t('button.save') }}</button>
                       </div>
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td colspan="2">
                       <textarea id="accessDesc" name="accessDesc" v-model="userData.memo"></textarea>
                     </td>
                   </tr>
                 </tbody>
               </table>

             </div>

          </article>
        </div>
      </div>

      <div class="allwrap" v-if="type == 'gamerate'">
        <ul class="setTab">
          <li :class="{ active: setRate === 'casino' }" @click="changeTabRate('casino')">{{$t('common.rateSettingByGame')}}({{ $t('table.head.casino') }})</li>
          <li :class="{ active: setRate === 'slot' }" @click="changeTabRate('slot')">{{$t('common.rateSettingByGame')}}({{ $t('table.head.slot') }})</li>
          <li :class="{ active: setRate === 'minigame' }" @click="changeTabRate('minigame')">{{$t('common.rateSettingByGame')}}({{ $t('table.head.mini') }})</li>
          <li :class="{ active: setRate === 'sport' }" @click="changeTabRate('sport')">{{$t('common.rateSettingByGame')}}({{ $t('table.head.sport') }})</li>
        </ul>
        <button class="btn-layout user-btn-bg btn-rate" @click="userRateUpdate">{{ $t('button.save') }}</button>
        <div class="notflex content_wrap w100w">
          <table>
            <colgroup v-if="setRate === 'casino' || setRate === 'slot' || setRate === 'sport'">
              <col width="20%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
            </colgroup>
            <colgroup v-if="setRate === 'minigame'">
              <col width="20%">
              <col width="13.33%">
              <col width="13.33%">
              <col width="13.33%">
              <col width="13.33%">
              <col width="13.33%">
              <col width="13.33%">
            </colgroup>
            <colgroup v-if="setRate === 'sport'">
              <col width="20%">
              <col width="8.89%">
              <col width="8.89%">
              <col width="8.89%">
            </colgroup>
            <thead>
              <tr >
                <th>{{ $t('common.allApply') }}</th>
                <th>{{ $t('common.name') }}</th>
                <th>{{ $t('common.rolling') }}</th>
                <th>{{ $t('common.losing') }}</th>
                <th v-if="setRate != 'sport'">{{ $t('common.name') }}</th>
                <th v-if="setRate != 'sport'">{{ $t('common.rolling') }}</th>
                <th v-if="setRate != 'sport'">{{ $t('common.losing') }}</th>
                <th v-if="setRate != 'minigame' && setRate != 'sport'">{{ $t('common.name') }}</th>
                <th v-if="setRate != 'minigame' && setRate != 'sport'">{{ $t('common.rolling') }}</th>
                <th v-if="setRate != 'minigame' && setRate != 'sport'">{{ $t('common.losing') }}</th>
              </tr>
            </thead>
          </table>
          <div v-if="setRate === 'casino'" class="rateSetWrap">
            <div class="rateL w20w">
              <div>
                <span>{{ $t('common.rolling') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_point" />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateAllMax(rateList, rateGroup.etc1)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('point', 'casino')">{{ $t('table.head.applied') }}</button>
              </div>
              <div>
                <span>{{ $t('common.losing') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateLoseAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_lose"  />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateLoseAllMax(rateList, rateGroup.etc1, userData.recommenderId)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('lose', 'casino')">{{ $t('table.head.applied') }}</button>
              </div>
            </div>
            <div class="rateR w80w">
              <template v-for="item in rateList" :key="item.code">
                <div class="rate-content w33w" v-if="item.groupCode === 'casino'">
                  <div class="title" v-bind:class="item.groupCode">
                    <span class="mr-5">{{ item.gameName }}</span>
                  </div>
                  <div>
                    <div class="rollcount ">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.pointRate" />
                    </div>
                  </div>
                  <div>
                    <div class="rollcount">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.loseRate" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="setRate === 'slot'" class="rateSetWrap">
            <div class="rateL w20w">
              <div>
                <span>{{ $t('common.rolling') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_point" />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateAllMax(rateList, rateGroup.etc1)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('point', 'slot')">{{ $t('table.head.applied') }}</button>
              </div>
              <div>
                <span>{{ $t('common.losing') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateLoseAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_lose"  />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateLoseAllMax(rateList, rateGroup.etc1, userData.recommenderId)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('lose', 'slot')">{{ $t('table.head.applied') }}</button>
              </div>
            </div>
            <div class="rateR w80w" >
              <template v-for="(item, index) in rateList" :key="item.gameType">
                <div class="rate-content w33w" v-if="item.groupCode === 'slot'">
                  <div class="title" v-bind:class="item.groupCode">
                    <span class="mr-5">{{ item.gameName }}</span>
                  </div>
                  <div>
                    <div class="rollcount ">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.pointRate" />
                    </div>
                  </div>
                  <div>
                    <div class="rollcount">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.loseRate" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="setRate === 'minigame'" class="rateSetWrap">
            <div class="rateL w20w">
              <div>
                <span>{{ $t('common.rolling') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_point" />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateAllMax(rateList, rateGroup.etc1)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('point', 'minigame')">{{ $t('table.head.applied') }}</button>
              </div>
              <div>
                <span>{{ $t('common.losing') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateLoseAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_lose"  />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateLoseAllMax(rateList, rateGroup.etc1, userData.recommenderId)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('lose', 'minigame')">{{ $t('table.head.applied') }}</button>
              </div>
            </div>
            <div class="rateR w80w">
              <template v-for="(item, index) in rateList" :key="item.gameType">
                <div class="rate-content" v-bind:class="item.groupCode" v-if="item.groupCode === 'minigame'">
                  <div class="title">
                    <span class="mr-5">{{ item.gameName }}</span>
                  </div>
                  <template v-if="item.gameType === '33'">
                     <div class="minibtn">
                       <a class="btn-layout btn-apply" @click="minibtn=!minibtn">{{ $t('common.rateDetail') }}</a>
                     </div>
                     <div v-if="minibtn" class="minibox">
                       <a @click="minibtn=!minibtn" class="close"><i class="fa fa-times" aria-hidden="true"></i></a>
                       <table>
                         <template v-for="(mini, miniIndex) in miniGameRateList" :key="mini.rateType">
                           <tr>
                             <td rowspan="2" class="bg">
                               {{mini.rateType === 'SINGLE' ? '일반': '조합'}}
                             </td>
                             <td>{{ $t('common.rolling') }}</td>
                             <td>
                                <button type="button" class="btn-layout btn-range btn-min" @click="onMiniChangeByLimit(mini.minPointRate, 'point', miniIndex)">{{ $t('table.head.min') }}</button>
                                <input type="number" :class="{'error' : mini.errorP}" @change="miniRateLimit(mini.maxPointRate, mini.minPointRate, mini.pointRate, miniIndex, 'P')" class="w80" v-model="mini.pointRate" />
                                <button type="button" class="btn-layout btn-range btn-max" @click="onMiniChangeByLimit(mini.maxPointRate, 'point', miniIndex)">{{ $t('table.head.max') }}</button>
                             </td>
                           </tr>
                           <tr>
                             <td>{{ $t('common.losing') }}</td>
                             <td>
                                <button type="button" class="btn-layout btn-range btn-min" @click="onMiniChangeByLimit(mini.minLoseRate, 'lose', miniIndex)">{{ $t('table.head.min') }}</button>
                                <input type="number" :class="{'error' : mini.errorL}" class="w80" @change="miniRateLimit(mini.maxLoseRate, mini.minLoseRate, mini.loseRate, miniIndex, 'L')" v-model="mini.loseRate" />
                                <button type="button" class="btn-layout btn-range btn-max" @click="onMiniChangeByLimit(mini.maxLoseRate, 'lose', miniIndex)">{{ $t('table.head.max') }}</button>
                             </td>
                           </tr>
                         </template>
                       </table>
                       <button type="button" class="btn-layout btn-range btn-max save" @click="onSaveMiniRate">{{ $t('button.save') }}</button>
                    </div>
                  </template>
                  <template v-else>
                     <div>
                       <div class="rollcount">
                         <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.pointRate" />
                       </div>
                     </div>
                     <div>
                       <div class="rollcount">
                         <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.loseRate" />
                       </div>
                     </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <div v-if="setRate === 'sport'" class="rateSetWrap">
            <div class="rateL w20w">
              <div>
                <span>{{ $t('common.rolling') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_point" />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateAllMax(rateList, rateGroup.etc1)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('point', 'casino')">{{ $t('table.head.applied') }}</button>
              </div>
              <div>
                <span>{{ $t('common.losing') }}</span>
                <div>
                  <button type="button" class="btn-layout btn-range btn-min" @click="rateLoseAllMin(rateList, rateGroup.etc1)">{{ $t('table.head.min') }}</button>
                  <input type="number" class="w50" @change="totalRateLimit('point')" v-model="batchRate_lose"  />
                  <button type="button" class="btn-layout btn-range btn-max" @click="rateLoseAllMax(rateList, rateGroup.etc1, userData.recommenderId)">{{ $t('table.head.max') }}</button>
                </div>
                <button type="button" class="btn-layout btn-apply" @click="rateAllChange('lose', 'casino')">{{ $t('table.head.applied') }}</button>
              </div>
            </div>
            <div class="rateR w80w">
              <template v-for="item in rateList" :key="item.code">
                <div class="rate-content w33w" v-if="item.groupCode === 'sport'">
                  <div class="title" v-bind:class="item.groupCode">
                    <span class="mr-5">{{ item.gameName }}</span>
                  </div>
                  <div>
                    <div class="rollcount ">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.pointRate" />
                    </div>
                  </div>
                  <div>
                    <div class="rollcount">
                      <input type="number" @change="rateLimit(index, item.maxRate)" v-model="item.loseRate" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="allwrap" v-if="type == 'betsetting'">
        <ul class="setTab">
          <li :class="{ active: setBet === 'casino' }" @click="changeTabBet('casino')">{{ $t('user.betByGame') }}({{ $t('table.head.casino') }})</li>
          <li :class="{ active: setBet === 'slot' }" @click="changeTabBet('slot')">{{ $t('user.betByGame') }}({{ $t('table.head.slot') }})</li>
          <li :class="{ active: setBet === 'minigame' }" @click="changeTabBet('minigame')">{{ $t('user.betByGame') }}({{ $t('table.head.mini') }})</li>
          <li :class="{ active: setBet === 'sport' }" @click="changeTabBet('sport')">{{ $t('user.betByGame') }}({{ $t('table.head.sport') }})</li>
        </ul>
        <button class="btn-layout user-btn-bg btn-rate" @click="userRateUpdate">{{ $t('button.save') }}</button>
        <div class="notflex content_wrap w100w">
          <table>
            <thead>
              <tr>
                <th>{{ $t('table.head.gamelist') }}</th>
              </tr>
            </thead>
          </table>
          <div class="gameSetWrap" v-if="setBet === 'casino'">
            <template v-for="item in rateList" :key="item.code">
              <div class="gameSet" v-if="item.groupCode === 'casino'">
                <span class="itemtxt">{{ item.gameName }}</span>
                <div>
                  <input type="checkbox" class="set-switch" v-model="item.betYn" />
                </div>
              </div>
            </template>
          </div>
          <div class="gameSetWrap" v-if="setBet === 'slot'">
            <template v-for="item in rateList" :key="item.code">
              <div class="gameSet" v-if="item.groupCode === 'slot'">
                <span class="itemtxt">{{ item.gameName }}</span>
                <div>
                  <input type="checkbox" class="set-switch" v-model="item.betYn" />
                </div>
              </div>
            </template>
          </div>
          <div class="gameSetWrap" v-if="setBet === 'minigame'">
            <template v-for="item in rateList" :key="item.code">
              <div class="gameSet" v-if="item.groupCode === 'minigame'">
                <span class="itemtxt">{{ item.gameName }}</span>
                <div>
                  <input type="checkbox" class="set-switch" v-model="item.betYn" />
                </div>
              </div>
            </template>
          </div>
          <div class="gameSetWrap" v-if="setBet === 'sport'">
            <template v-for="item in rateList" :key="item.code">
              <div class="gameSet" v-if="item.groupCode === 'sport'">
                <span class="itemtxt">{{ item.gameName }}</span>
                <div>
                  <input type="checkbox" class="set-switch" v-model="item.betYn" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="allwrap ratewrap" v-if="type == 'rateSet'">
        <div class="notflex content_wrap w50w">
          <button class="btn-layout user-btn-bg btn-rate" @click="onRateNewSave">{{ $t('button.save') }}</button>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t('common.rolling') }}</th>
                <th>{{ $t('common.losing') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="memRollingInfo">
                <template v-for="item in memRollingInfo" :key="item.cateCode">
                  <tr v-if="item.detailSetYn === 'N'">
                    <td>
                      {{ item.cateCodeName }}
                    </td>
                    <td>
                      <div class="btnwrap">
                        <button type="button" class="btn-layout btn-range btn-min" @click="onNewChangeByLimit(item.minPointRate, 'point', item)">{{ $t('table.head.min') }}<i>({{ item.minPointRate }})</i></button>
                        <input type="number" class="w50" :class="{'error' : item.errorP}" v-model="item.pointRate" @change="newRateLimit(item.maxPointRate, item.minPointRate, item.pointRate, 'P', item)"/>
                        <button type="button" class="btn-layout btn-range btn-max" @click="onNewChangeByLimit(item.maxPointRate, 'point', item)">{{ $t('table.head.max') }}<i>({{ item.maxPointRate }})</i></button>
                      </div>
                    </td>
                    <td>
                      <div class="btnwrap">
                        <button type="button" class="btn-layout btn-range btn-min" @click="onNewChangeByLimit(item.minLoseRate, 'lose', item)">{{ $t('table.head.min') }}<i>({{ item.minLoseRate }})</i></button>
                        <input type="number" class="w50" :class="{'error' : item.errorR}"  v-model="item.loseRate" @change="newRateLimit(item.maxLoseRate, item.minLoseRate, item.loseRate, 'R', item)" />
                        <button type="button" class="btn-layout btn-range btn-max" @click="onNewChangeByLimit(item.maxLoseRate, 'lose', item)">{{ $t('table.head.max') }}<i>({{ item.maxLoseRate }})</i></button>
                      </div>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ item.cateCodeName }}</td>
                    <td colspan="2">
                      <div class="minibtn">
                        <a class="btn-layout btn-apply" @click="onRateDetailOpen(item)">{{ $t('common.rateDetail') }}</a>
                      </div>
                      <div v-if="item.isOpenDetail" class="minibox">
                        <a @click="onRateDetailOpen(item)" class="close"><i class="fa fa-times" aria-hidden="true"></i></a>
                        <table>
                          <template v-for="(mini, miniIndex) in item.miniGameRateList" :key="mini.rateType">
                            <tr>
                              <td rowspan="2" class="bg">
                                {{mini.rateType === 'SINGLE' ? '일반': '조합'}}
                              </td>
                              <td>{{ $t('common.rolling') }}</td>
                              <td>
                                <button type="button" class="btn-layout btn-range btn-min" @click="onMiniNewChangeByLimit(mini.minPointRate, 'point', miniIndex, item)">{{ $t('table.head.min') }}<i>({{ mini.minPointRate }})</i></button>
                                <input type="number" :class="{'error' : mini.errorP}" @change="miniNewRateLimit(mini.maxPointRate, mini.minPointRate, mini.pointRate, miniIndex, 'P', item)" class="w80" v-model="mini.pointRate" />
                                <button type="button" class="btn-layout btn-range btn-max" @click="onMiniNewChangeByLimit(mini.maxPointRate, 'point', miniIndex, item)">{{ $t('table.head.max') }}<i>( {{ mini.maxPointRate }} )</i></button>
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t('common.losing') }}</td>
                              <td>
                                <button type="button" class="btn-layout btn-range btn-min" @click="onMiniNewChangeByLimit(mini.minLoseRate, 'lose', miniIndex, item)">{{ $t('table.head.min') }}<i>( {{ mini.minLoseRate }} )</i></button>
                                <input type="number" :class="{'error' : mini.errorL}" class="w80" @change="miniNewRateLimit(mini.maxLoseRate, mini.minLoseRate, mini.loseRate, miniIndex, 'L', item)" v-model="mini.loseRate" />
                                <button type="button" class="btn-layout btn-range btn-max" @click="onMiniNewChangeByLimit(mini.maxLoseRate, 'lose', miniIndex, item)">{{ $t('table.head.max') }}<i>( {{ mini.maxLoseRate }} )</i></button>
                              </td>
                            </tr>
                          </template>
                        </table>
                        <button type="button" class="btn-layout btn-range btn-max save" @click="onSaveNewMiniRate(item)">{{ $t('button.save') }}</button>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-wrapper" v-if="cashModal.active">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>{{ cashModal.type }}</h2>
             <h4>{{ getDomain () }}</h4>
             <a class="btn" @click="cashModalClose"><i class="fas fa-times"></i></a>
           </div>
           <div class="modal-content2">
             <div class="onlyNum">
                <input id="newCashamt" type="number" v-model="cashModal.model.amt" />
                <label for="newCashamt">{{ numberWithCommas(cashModal.model.amt || 0) }}</label>
             </div>
             <div class="modal-btns" v-if="cashModal.type === '보유금'">
                <a @click="cashApply('in',userData.memId)">{{ $t('button.payment') }}</a>
                <a @click="cashApply('out',userData.memId)">{{ $t('common.coll') }}</a>
             </div>
             <div class="modal-btns" v-if="cashModal.type === '포인트'">
                <a @click="pointApply('in', userData.memId)">{{ $t('button.payment') }}</a>
                <a @click="pointApply('out', userData.memId)">{{ $t('common.coll') }}</a>
             </div>
           </div>
        </div>
      </div>
      <!-- 보험 누르기 설정 팝업 -->
      <div class="modal-wrapper" v-if="isOpenInsurePush">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>누르기 설정</h2>
             <a class="btn" @click="onCloseInsurePush">
                <i class="fas fa-times"></i>
             </a>
           </div>
           <div class="modal-content2">
             <template v-for="(item) in insurePushList" :key="item.vendorCode">
                <p class="modal-txt">{{ item.vendorName }}</p>
                 <ul class="modal-info">
                    <li>
                       <template v-for="subItem in item.children" :key="subItem">
                          <span>{{ subItem.pushTypeName }}</span>
                          <span><input type="checkbox" class="set-switch" :checked="subItem.pushYn === 'Y'" @click="onChangeInsurePush(subItem)" /></span>
                       </template>
                    </li>
                 </ul>
              </template>
              <div class="modal-btns">
                 <a @click="onSaveInsurePush">{{ $t('button.save') }}</a>
              </div>
           </div>
        </div>
        <!--div class="modal-item">
          <article class="modal-title">
            <div class="wrapper-title">
              <span>에볼루션</span>
            </div>
            <button class="btn-link modal-close" type="button" @click="biModalClose"><i class="fas fa-times"></i></button>
          </article>
          <article class="fx-item-auto">
            <table>
              <tr>
                <td>
                  <ul>
                    <li><span>바카라 타이페어 누르기</span></li>
                    <li><input type="checkbox" class="set-switch" @click="switchToggle" /></li>
                  </ul>
                </td>
              </tr>
            </table>
            <button class="btn-layout btn-red save" type="button">{{ $t('button.save') }}</button>
          </article>
        </div-->
      </div>
      <div class="modalWrap bimodal" v-if="biModals.active">
        <div class="modal-item">
          <article class="modal-title">
            <div class="wrapper-title">
              <span>하이로우</span>
            </div>
            <button class="btn-link modal-close" type="button" @click="biModalsClose"><i class="fas fa-times"></i></button>
          </article>
          <article class="fx-item-auto">
            <table>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li><span>{{ $t('table.head.normal') }}</span></li>
                      <li><input type="checkbox" class="set-switch" @click="switchToggle" /></li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li><span>{{ $t('table.head.combi') }}</span></li>
                      <li><input type="checkbox" class="set-switch" @click="switchToggle" /></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn-layout btn-red save" type="button">{{ $t('button.save') }}</button>
          </article>
        </div>
      </div>
      <div class="modalWrap bimodal" v-if="passingModal.active">
        <div class="modal-item">
          <article class="modal-title">
            <div class="wrapper-title">
              <span>{{ $t('common.passing') }} {{ $t('user.option') }}</span>
            </div>
            <button class="btn-link modal-close" type="button" @click="passingModalClose"><i class="fas fa-times"></i></button>
          </article>
          <article class="fx-item-auto">
            <table>
              <thead>
                <tr>
                  <td colspan="2">{{ $t('common.stock') }}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li><span>풍성한 바카라</span></li>
                      <li><input type="checkbox" class="set-switch" @click="switchToggle" /></li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li><span>라이트닝 바카라</span></li>
                      <li><input type="checkbox" class="set-switch" @click="switchToggle" /></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
        </div>
      </div>
      <msg-modal v-if="msgOpen" :boardType="'msgList'" :recieveId="userData.memId" :targetType="'member'" @modalClose="modalClose"></msg-modal>
    </template>
  </div>
</template>

<script>
import lodash from 'lodash'
import {
  memberDetail,
  memberRate,
  getCode,
  comBank,
  memberRateUpdate,
  memLogout,
  memStatusChange,
  memberModify,
  cashIn,
  cashOut,
  memberPointInOut,
  getGameCodes,
  resetPassword,
  partnerLevelUp,
  setMoneyMerge,
  setUserBonusSetting,
  getMultipleRateInfo,
  setMultipleRateInfo, memberNewRateUpdate, memberLockBottom, memberupdateBlank, memberupdateAllBlank, memberAbuseInit
} from '@/api/member.js'
import { partnerList } from '@/api/partner.js'
import { replaceDateT, numberWithCommas, onlyNumber, thousand } from '@/libs/utils.js'
import subTitle from '@/components/main/PageSubTitle'
import msgModal from '@/components/common/msgModal'
import { mapState } from 'vuex'
import { getInsurePushList, saveInsurePush } from '@/api/insured'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'userDetail',
  components: {
    subTitle,
    msgModal
  },
  computed: {
    ...mapState([
      'isInsured',
      'siteOption'
    ]),
    memId () {
      return this.userData.memId
    },
    siteId () {
      return this.siteOption.siteId
    }
  },
  data () {
    return {
      setRate: 'casino',
      setBet: 'casino',
      type: 'userdetail',
      currentPassword: '',
      currentMenu: 'tab1',
      userData: null,
      userBonus: null,
      scontentHeight: '0',
      batchRate_insured: '0.00',
      batchRate_point: '0.00',
      batchRate_lose: '0.00',
      bankList: [],
      levels: '',
      cashModal: {
        active: false,
        type: '',
        action: '',
        model: {
          memId: '',
          amt: '',
          memo: ''
        }
      },
      isOpenInsurePush: false,
      biModals: {
        active: false
      },
      passingModal: {
        active: false
      },
      subUserList: [],
      reqData: {
        memId: this.$route.query.id
      },
      insuredLimit: '',
      userRate: [],
      gameList: [],
      rateUpdate: {
        siteId: '',
        memId: '',
        rateList: []
      },
      memRollingInfo: [],
      originRate: [],
      rateList: [],
      insuredRateList: [],
      modifyData: {
        siteId: '',
        memId: '',
        memNick: '',
        memName: '',
        memStatus: '',
        recommenderId: '',
        loginYn: '',
        memBlackYn: '',
        memLockYn: '',
        accInfoRequestYn: '',
        memLevel: '',
        sportsLevel: '',
        casinoLevel: '',
        slotLevel: '',
        mgLevel: '',
        pointExchangeYn: '',
        memo: '',
        insuredUpRate: '',
        insuredYn: '',
        memberCommissionRate: [
          {
            gameType: '',
            pointRate: '',
            useYn: '',
            betYn: ''
          }
        ],
        memberGameBetYn: [
          {
            gameType: '',
            pointRate: '',
            useYn: '',
            betYn: ''
          }
        ],
        memberFlow: []
      },
      rateGroup: {
        etc1: '',
        etc2: '',
        etc3: ''
      },
      msgOpen: false,
      isBlankSpinCasinoRate: false,
      isBlankSpinSlotRate: false,
      minibtn: false,
      miniGameRateList: [],
      insurePushList: {}
    }
  },
  methods: {
    onClickAbuseInit (item) {
      if (confirm('초기화 하시겠습니까?')) {
        const param = {
          memId: item.memId
        }

        memberAbuseInit(param).then(res => {
          if (res.resultCode === '0') {
            alert('초기화 되었습니다.')
            this.userData.abuseLockCnt = 0
            this.userData.abuseLock = null
          } else {
            alert(res.resultMessage)
          }
        })
      }
    },
    thousand,
    getDomain () {
      return location.host
    },
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    onRateDetailOpen (item) {
      if (!item.isOpenDetail) {
        const params = {
          siteId: this.siteOption.siteId,
          memId: this.userData.memId,
          vendorCode: item.cateCode
        }

        getMultipleRateInfo(params).then(res => {
          console.log(res)
          if (res.resultCode === '0') {
            console.log(res.data.multipleRateInfo.rateList)
            item.miniGameRateList = res.data.multipleRateInfo.rateList

            item.miniGameRateList.forEach(rate => {
              rate.errorP = false
              rate.errorL = false
            })
          }
        })
      } else {
        item.miniGameRateList = []
      }
      item.isOpenDetail = !item.isOpenDetail
    },
    onMiniNewChangeByLimit (newValue, type, index, item) {
      console.log(newValue, type, index)
      if (type === 'point') {
        item.miniGameRateList[index].pointRate = newValue
      } else {
        item.miniGameRateList[index].loseRate = newValue
      }
    },
    onNewChangeByLimit (newValue, type, item) {
      console.log(newValue, type)
      if (type === 'point') {
        item.pointRate = newValue
      } else {
        item.loseRate = newValue
      }
    },
    onMiniChangeByLimit (newValue, type, index) {
      console.log(newValue, type, index)
      if (type === 'point') {
        this.miniGameRateList[index].pointRate = newValue
      } else {
        this.miniGameRateList[index].loseRate = newValue
      }
    },
    onSaveNewMiniRate (item) {
      let isError = false

      item.miniGameRateList.forEach(rate => {
        if (rate.errorP || rate.errorL) {
          isError = true
        }
      })

      if (!isError) {
        const data = {
          siteId: this.siteOption.siteId,
          memId: this.userData.memId,
          vendorCode: '33',
          rateList: item.miniGameRateList
        }
        setMultipleRateInfo(data).then(res => {
          if (res.resultCode === '0') {
            alert('저장 완료되었습니다.')
            this.onRateDetailOpen(item)
          } else {
            alert(res.resultMessage)
          }
        })
      }
    },
    onSaveMiniRate () {
      let isError = false

      this.miniGameRateList.forEach(item => {
        if (item.errorP || item.errorL) {
          isError = true
        }
      })

      if (!isError) {
        const data = {
          siteId: this.siteOption.siteId,
          memId: this.userData.memId,
          vendorCode: '33',
          rateList: this.miniGameRateList
        }
        setMultipleRateInfo(data).then(res => {
          if (res.resultCode === '0') {
            alert('저장 완료되었습니다.')
            this.minibtn = false
          } else {
            alert(res.resultMessage)
          }
        })
      }
    },
    miniRateLimit (max, min, value, index, type) {
      this.miniGameRateList[index].errorP = false
      this.miniGameRateList[index].errorR = false
      if (value > max) {
        this.miniGameRateList[index]['error' + type] = true
      } else if (min > value) {
        this.miniGameRateList[index]['error' + type] = true
      }
    },
    onRateNewSave () {
      this.emitter.emit('Loading', true)

      const rateList = []

      this.memRollingInfo.forEach(item => {
        rateList.push({
          category: item.category,
          cateCode: item.cateCode,
          pointRate: item.pointRate,
          loseRate: item.loseRate
        })
      })

      const params = {
        siteId: this.siteOption.siteId,
        memId: this.userData.memId,
        rateList
      }

      memberNewRateUpdate(params).then(res => {
        if (res.resultCode === '0') {
          alert('저장 완료되었습니다.')
        } else {
          alert(res.resultMessage)
        }
        this.emitter.emit('Loading', false)
      })
    },
    newRateLimit (max, min, value, type, item) {
      item.errorP = false
      item.errorR = false
      if (value > max) {
        item['error' + type] = true
      } else if (min > value) {
        item['error' + type] = true
      }
    },
    miniNewRateLimit (max, min, value, index, type, item) {
      item.miniGameRateList[index].errorP = false
      item.miniGameRateList[index].errorR = false
      if (value > max) {
        item.miniGameRateList[index]['error' + type] = true
      } else if (min > value) {
        item.miniGameRateList[index]['error' + type] = true
      }
    },
    onlyNumber,
    changeTabRate (setRate) {
      if (setRate === 'minigame') {
        const params = {
          siteId: this.siteOption.siteId,
          memId: this.userData.memId,
          vendorCode: '33'
        }

        getMultipleRateInfo(params).then(res => {
          console.log(res)
          if (res.resultCode === '0') {
            this.miniGameRateList = res.data.multipleRateInfo.rateList

            this.miniGameRateList.forEach(item => {
              item.errorP = false
              item.errorL = false
            })
          }
        })
      }

      this.setRate = setRate
    },
    changeTabBet (setBet) {
      this.setBet = setBet
    },
    formatValueWithCommas (value) {
      return value.toString().replace(/\B(?=(\d{10})+(?!\d))/g, ',')
    },
    async setUserBonus (field) {
      const confirmMessage = '보너스 설정을 저장하시겠습니까?'
      if (confirm(confirmMessage)) {
        const param = {
          memId: this.userData.memId,
          fstChrgByJoinRate: null,
          fstChrgByDailyRate: null,
          fstChrgByEveryTimeRate: null,
          bonusLimit: null
        }

        param[field] = this.userBonus[field]

        if (field === 'bonusLimit') {
          param.bonusLimit = Number(this.userBonus.bonusLimit.replace(/,/g, ''))
        }

        await setUserBonusSetting(param).then(async res => {
          console.log(res)
          if (res.resultCode === '0') {
            alert('정상적으로 보너스 설정이 저장되었습니다.')
            await this.getUserDetail()
          } else {
            alert('오류가 발생하였습니다. 다시 시도해주세요.')
          }
        })
      }
    },
    async moneyMerge () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      if (confirm(confirmMessage)) {
        const param = {
          memId: this.userData.memId
        }

        await setMoneyMerge(param).then(async res => {
          console.log(res)
          if (res.resultCode === '0') {
            alert('정상적으로 통합머니 전환이 완료되었습니다.')
            await this.getUserDetail()
          } else {
            alert('오류가 발생하였습니다. 다시 시도해주세요.')
          }
        })
      }
    },
    async changePartner () {
      const param = {
        siteId: this.siteInfo.siteId,
        memId: this.userData.memId
      }

      console.log(param)

      const confirmMessage = '계속 진행하면 이 회원은 파트너로 전환됩니다. 전환된뒤에는 되돌릴수없습니다. 그래도 진행하시겠습니까?'

      if (confirm(confirmMessage)) {
        await partnerLevelUp(param).then(res => {
          console.log(res)
          if (res.resultCode === '0') {
            alert('정상적으로 파트너 전환이 완료되었습니다.')
          } else {
            alert('오류가 발생하였습니다. 다시 시도해주세요.')
          }
        })
      }
    },
    onChangeType (type) {
      this.type = type
    },
    async resetPassword () {
      if (confirm('비밀번호를 초기화하시겠습니까?')) {
        const params = {
          memId: this.userData.memId
        }
        const res = await resetPassword(params)
        if (res.resultCode === '0') {
          alert('비밀번호가 초기화되었습니다.')
        } else {
          alert('비밀번호 초기화에 실패하였습니다. 조금 후 다시 시도해주세요.')
        }
      }
    },
    async lockBotMemberSetting () {
      if (confirm('설정된 값으로 하부전체회원(파트너포함)에게 설정됩니다. 동의하시면 진행해주세요.\n')) {
        const params = {
          memId: this.userData.memId,
          memLockYn: this.userData.memLockYn ? 'Y' : 'N'
        }

        memberLockBottom(params).then(res => {
          if (res.resultCode === '0') {
            alert('정상적으로 하부전체회원(파트너포함)에게 설정되었습니다.')
          } else {
            alert('오류가 발생하였습니다. 다시 시도해주세요.')
          }
        })
      }
    },
    async blankBotMemberSetting () {
      const confirmationMessage = `설정된 값으로 하부전체회원(파트너포함)에게 설정됩니다. 동의하시면 진행해주세요.\n
"카지노" 공베팅은 다음과 같은 공식이 적용됩니다.
당첨금이 베팅금 보다 큰 경우는 확률계산에서 제외되어 공베팅이 적용되지 않습니다.
이점 숙지하시고 이용해 주시기 바랍니다.
      `
      if (confirm(confirmationMessage)) {
        const params = {
          memId: this.userData.memId,
          blankSpinCasinoRate: this.userData.blankSpinCasinoRate,
          blankSpinSlotRate: this.userData.blankSpinSlotRate
        }

        await memberupdateAllBlank(params).then(res => {
          if (res.resultCode === '0') {
            alert('하부 전체 저장 완료')
          } else {
            alert('하부 전체 저장 실패. 다시 시도해주세요')
          }
        })
      }
    },
    async userSettingUpdateBlank () {
      const confirmationMessage = `설정된 값으로 직하부회원(파트너제외)에게 설정됩니다. 동의하시면 진행해주세요.\n
"카지노" 공베팅은 다음과 같은 공식이 적용됩니다.
당첨금이 베팅금 보다 큰 경우는 확률계산에서 제외되어 공베팅이 적용되지 않습니다.
이점 숙지하시고 이용해 주시기 바랍니다.
      `
      if (confirm(confirmationMessage)) {
        const reqData = {
          memId: this.userData.memId,
          blankSpinCasinoRate: Number(this.userData.blankSpinCasinoRate),
          blankSpinSlotRate: Number(this.userData.blankSpinSlotRate)
        }

        const res = await memberupdateBlank(reqData)
        if (res.resultCode === '0') {
          alert('기능설정 완료')
        } else {
          alert('기능설정 실패, 다시 시도해주세요.')
        }
      }
    },
    setCurrentTab (type) {
      this.currentMenu = type
      document.querySelector('#' + type).scrollIntoView({ behavior: 'smooth' })
    },
    openRateSetting () {
      const name = 'rateSettings'
      const targetId = this.memberDetail.siteId

      const size = {
        width: 585,
        height: 840
      }
      const position = {
        top: 0,
        left: 150
      }
      const routeData = this.$router.resolve({ name, params: { id: targetId } })
      window.open(routeData.href, 'rateSetting', `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    // rateAllChange() {
    //   console.log('click')
    //   const target = this.$refs.ptRateControl;
    //   let value = target.value;
    //   console.log(value);
    //   if (value > 100) {
    //     // console.log("value=1000");
    //     value = 100;
    //   } else if (value < 0) {
    //     // console.log("value=0");
    //     value = 0;
    //   }
    //   const ptRates = this.memberDetail.memberCommissionRate;
    //   for (const item of ptRates) {
    //     const itemMaxRate = item.maxRate;
    //     console.log(item.maxRate);
    //     console.log(value);
    //     console.log(itemMaxRate < value);
    //     // const itemRate = item.pointRate;
    //     if (Number(itemMaxRate) < Number(value)) {
    //       item.pointRate = itemMaxRate;
    //     } else {
    //       item.pointRate = value;
    //     }
    //   }
    // },
    totalRateLimit (type) {
      let controlRate = 0
      if (type === 'point') {
        controlRate = this.batchRate_point
      } else if (type === 'insured') {
        controlRate = this.batchRate_insured
      }
      const n = Number(controlRate).toFixed(2)
      if (n >= 100) {
        controlRate = (100).toFixed(2)
      } else if (n < 0) {
        controlRate = (0).toFixed(2)
      } else {
        controlRate = Number(controlRate).toFixed(2)
      }
    },
    rateAllChange (type, groupCode) {
      let controlRate = '0.00'
      let rateList = []
      if (type === 'point') {
        controlRate = this.batchRate_point
        rateList = this.rateList
      } else if (type === 'insured') {
        controlRate = this.batchRate_insured
        rateList = this.insuredRateList
      } else if (type === 'lose') {
        controlRate = this.batchRate_lose
        rateList = this.rateList
      }

      for (const item of rateList) {
        let limit
        if (type === 'point') {
          limit = Number(item.maxPointRate)
        } else if (type === 'insured') {
          limit = Number(item.maxRate)
        } else if (type === 'lose') {
          limit = Number(item.maxLoseRate)
        }
        const controlRateNumber = Number(controlRate)
        if (controlRateNumber > limit) {
          if (!groupCode || item.groupCode === groupCode) {
            if (type === 'lose') {
              item.loseRate = limit.toFixed(2)
            } else {
              item.pointRate = limit.toFixed(2)
            }
          }
        } else {
          if (!groupCode || item.groupCode === groupCode) {
            if (type === 'lose') {
              item.loseRate = controlRateNumber.toFixed(2)
            } else {
              item.pointRate = controlRateNumber.toFixed(2)
            }
          }
        }
      }
    },
    async getLevels () {
      const data = await getCode('memLevel')
      this.levels = data
    },
    async _memLogout (memId) {
      const reqData = {
        memId
      }
      const res = await memLogout(reqData)
      if (res.resultCode === '0') {
        alert('로그아웃 처리 완료')
      } else {
        alert('로그아웃 처리 실패')
      }
    },

    async pointApply (type, memId) {
      let res = ''
      if (type === 'in') {
        const model = lodash.cloneDeep(this.cashModal.model)
        const req = {
          memId,
          pointType: 2,
          pointDesc: '관리자 포인트 지급',
          pointAmt: model.amt / Number(this.siteOption.cashDecimal),
          memo: ''
        }
        res = await memberPointInOut(req)
        if (res.resultCode === '0') {
          alert('지급완료')
          await this.getUserDetail()
          this.cashModalClose()
          this.cashModal.reqData.inAmt = ''
        } else if (res.resultCode === 'P006') {
          alert('포인트지급,회수 처리는 1분에 1건씩 처리됩니다. 잠시후 다시 시도해주세요')
        } else {
          alert('지급실패, 다시 시도해주세요.')
        }
      } else if (type === 'out') {
        const model = lodash.cloneDeep(this.cashModal.model)
        if (this.userData.pointAmt < model.amt) {
          model.amt = 0
          alert('회수포인트는 보유포인트보다 적어야 합니다.')
          return
        }
        const req = {
          memId,
          pointType: -2,
          pointDesc: '관리자 포인트 회수',
          pointAmt: model.amt / Number(this.siteOption.cashDecimal) * -1,
          memo: ''
        }
        res = await memberPointInOut(req)
        if (res.resultCode === '0') {
          alert('회수 완료')
          await this.getUserDetail()
          this.cashModalClose()
          this.cashModal.model.amt = ''
        } else {
          alert('회수실패, 다시 시도해주세요.')
        }
      }
    },
    async cashApply (type, memId) {
      console.log(this.siteOption)
      let res = ''
      if (type === 'in') {
        const model = lodash.cloneDeep(this.cashModal.model)
        const req = {
          memId,
          inAmt: model.amt / Number(this.siteOption.cashDecimal),
          memo: model.memo,
          type: '관리자 보유금 지급',
          inBonusYn: 'N'
        }
        res = await cashIn(req)
        if (res.resultCode === '0') {
          alert('지급완료')
          await this.getUserDetail()
          this.cashModalClose()
          this.cashModal.model.amt = ''
        } else {
          alert('지급실패, 다시 시도해주세요.')
        }
      } else if (type === 'out') {
        if (this.userData.cashAmt < this.cashModal.model.amt) {
          this.cashModal.model.amt = 0
          alert('회수금액은 보유금액보다 적어야 합니다.')
          return
        }
        const model = lodash.cloneDeep(this.cashModal.model)
        const req = {
          memId,
          outAmt: model.amt / Number(this.siteOption.cashDecimal),
          memo: model.memo,
          type: '관리자 보유금 회수',
          bonusRollingYn: 'N'
        }
        res = await cashOut(req)
        if (res.resultCode === '0') {
          alert('회수 완료')
          await this.getUserDetail()
          this.cashModalClose()
          this.cashModal.model.amt = ''
        } else {
          if (res.resultCode === 'C702') {
            alert('회수할 금액이 유저 보유금보다 많을 수 없습니다.')
          } else if (res.resultCode === 'C099') {
            alert(res.resultMessage)
          } else {
            alert('회수실패, 다시 시도해주세요.')
          }
        }
      }
    },
    async userInfoUpdate () {
      const reqData = {
        memId: this.userData.memId,
        memNick: this.userData.memNick,
        memName: this.userData.memName,
        memLevel: this.userData.memLevel,
        cashOutPass: this.userData.cashOutPass,
        bank: this.userData.bank,
        bankacc: this.userData.bankacc,
        memPhone: this.userData.memPhone,
        insuredYn: lodash.cloneDeep(this.userData.insuredYn),
        baseInsureBetAmt: this.userData.baseInsureBetAmt,
        isBiPushYn: this.userData.isBiPushYn
      }

      if (this.userData.memPass !== this.currentPassword) {
        reqData.memPass = this.currentPassword
      }

      if (reqData.insuredYn) {
        reqData.insuredYn = 'Y'
      } else {
        reqData.insuredYn = 'N'
      }

      if (reqData.isBiPushYn) {
        reqData.isBiPushYn = 'Y'
      } else {
        reqData.isBiPushYn = 'N'
      }

      for (const key in reqData) {
        if (reqData.bank && !reqData.bankacc) {
          alert(this.$t('table.head.bankacc') + '를 입력해주세요.')
          return
        }
        if (!reqData.bank && reqData.bankacc) {
          alert(this.$t('table.head.bank') + '를 입력해주세요.')
          return
        }
        if (!reqData[key]) {
          if (key === 'bank' && key === 'bankacc') {
            alert(this.$t(`table.head.${key}`) + '를 입력해주세요.')
            return
          }
        }
      }
      reqData.baseInsureBetAmt = Number(reqData.baseInsureBetAmt.replace(/\,/g, ''))
      const res = await memberModify(reqData)
      if (res.resultCode === '0') {
        alert('회원정보수정 완료')
      } else {
        alert('회원정보 변경실패, 다시 시도해주세요.')
      }
    },
    async userMemoUpdate () {
      const reqData = {
        memId: this.userData.memId,
        memo: this.userData.memo
      }
      const res = await memberModify(reqData)
      if (res.resultCode === '0') {
        alert('상담메모수정 완료')
      } else {
        alert('상담메모수정실패, 다시 시도해주세요.')
      }
    },
    async userGLevelUpdate () {
      const reqData = {
        memId: this.userData.memId,
        sportsLevel: this.userData.sportsLevel,
        casinoLevel: this.userData.casinoLevel,
        slotLevel: this.userData.slotLevel,
        mgLevel: this.userData.mgLevel
      }
      const res = await memberModify(reqData)
      if (res.resultCode === '0') {
        alert('게임레벨 수정 완료')
      } else {
        alert('게임레벨 수정 실패, 다시 시도해주세요.')
      }
    },
    async userRateUpdate () {
      const reqData = {
        siteId: this.userData.siteId,
        memId: this.userData.memId,
        rateList: []
      }
      const rateList = lodash.cloneDeep(this.rateList)
      for (const item of rateList) {
        if (item.pointRate) {
          if (Number(item.pointRate) > Number(item.maxPointRate)) {
            alert('롤링 포인트가 최대값보다 큽니다.')
            return false
          }
          if (Number(item.pointRate) < Number(item.minPointRate)) {
            alert('롤링 포인트가 최소값보다 큽니다.')
            return false
          }
        }
        if (item.loseRate) {
          if (Number(item.loseRate) > Number(item.maxLoseRate)) {
            alert('루징 포인트가 최대값보다 큽니다.')
            return false
          }
          if (Number(item.loseRate) < Number(item.minLoseRate)) {
            alert('루징 포인트가 최소값보다 큽니다.')
            return false
          }
        }

        const model = {
          useYn: 'Y',
          gameType: item.gameType,
          pointRate: item.pointRate,
          loseRate: item.loseRate,
          betYn: item.betYn ? 'Y' : 'N'
        }

        reqData.rateList.push(model)
      }
      console.log('req : ', reqData)
      const res = await memberRateUpdate(reqData)
      console.log('res', res)
      // await this.getUserDetail();
      // await this.setRateList();
      if (res.resultCode === '0') {
        alert('요율설정 완료')
      } else if (res.resultCode === 'U031') {
        alert(res.resultMessage)
      } else {
        alert('요율설정 실패, 다시 시도해주세요.')
      }
      // location.reload();
    },
    async userSettingUpdate () {
      const reqData = {
        memId: this.userData.memId,
        memName: this.userData.memName,
        accInfoRequestYn: this.userData.accInfoRequestYn,
        loginYn: this.userData.loginYn,
        memLockYn: this.userData.memLockYn,
        memBlackYn: this.userData.memBlackYn,
        pointExchangeYn: this.userData.pointExchangeYn,
        betYn: this.userData.betYn,
        recommendYn: this.userData.recommendYn
      }
      const req = lodash.cloneDeep(reqData)
      console.log(req)
      for (const key in req) {
        // console.log(typeof req[key] === "boolean");
        if (typeof req[key] === 'boolean') {
          if (req[key]) {
            req[key] = 'Y'
          } else {
            req[key] = 'N'
          }
        }
      }
      // return;
      const res = await memberModify(req)
      if (res.resultCode === '0') {
        alert('기능설정 완료')
      } else {
        alert('기능설정 실패, 다시 시도해주세요.')
      }
    },
    parentLinkMove (linkTarget, memId) {
      // console.log("move");
      window.open(`${linkTarget}?id=${memId}`, '_blank')
      // window.opener.location.href = `${linkTarget}?id=${memId}`;
    },
    parentLinkMovePopup (linkTarget, memId) {
      // console.log("move");
      // window.opener.location.href = `${linkTarget}?id=${memId}`;

      const name = 'CashMoveHistory'
      const size = {
        width: 1500,
        height: 1000
      }
      const position = {
        top: 100,
        left: Math.ceil((window.screen.width - size.width) / 2)
      }

      window.open(`${linkTarget}?id=${memId}`, `${name}`, `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
      /* `Detail_${type}`, `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no` */
    },
    async getSubMemberInfo () {
      const reqData = {
        memId: this.userData.memId,
        searchType: 'tree'
      }
      const res = await partnerList(reqData)
      // console.log(res);
      const list = res.data.list
      if (list) {
        this.userData.subMemCnt = list.length
      } else {
        this.userData.subMemCnt = 0
      }
      this.subUserList = list
      // console.log(this.subUserList);
    },
    switchToggle (event) {
      const toggle = event.target
      const text = toggle.previousElementSibling
      // console.log(toggle.checked)
      if (text) {
        if (toggle.checked) {
          text.classList.remove('off')
          text.classList.add('on')
        } else {
          text.classList.remove('on')
          text.classList.add('off')
        }
      }
    },
    async getBank () {
      // console.log(this.siteInfo);
      const req = {
        siteId: this.siteInfo.siteId
      }
      const res = await comBank(req)
      this.bankList = res.data.list
    },
    cashModalOpen (type) {
      const _type = type.split('_')
      if (_type[0] === 'cash') {
        this.cashModal.type = '보유금'
      } else if (_type[0] === 'point') {
        this.cashModal.type = '포인트'
      }

      /* if (_type[1] === 'in') {
        this.cashModal.action = 'in'
        this.cashModal.actionText = '지급'
      } else if (_type[1] === 'out') {
        this.cashModal.action = 'out'
        this.cashModal.actionText = '회수'
     } */

      this.cashModal.active = true
    },
    onOpenInsurePush () {
      this.emitter.emit('Loading', true)
      const params = {
        siteId: this.siteId,
        memId: this.memId
      }

      getInsurePushList(params).then(res => {
        if (res.resultCode === '0') {
          const insurePushList = res.data.insuredPushInfo.pushItemList

          insurePushList.forEach(item => {
            const vendorCode = item.vendorCode

            if (!this.insurePushList[vendorCode]) {
              this.insurePushList[vendorCode] = {
                vendorName: item.vendorName,
                children: []
              }
            }
            this.insurePushList[vendorCode].children.push(item)
          })
        } else {
          alert(res.resultMessage)
        }

        this.emitter.emit('Loading', false)
      }).catch(e => {
        alert(e)
        this.emitter.emit('Loading', false)
      })

      this.isOpenInsurePush = true
    },
    onCloseInsurePush () {
      this.isOpenInsurePush = false
      this.insurePushList = {}
    },
    onChangeInsurePush (item) {
      if (item.pushYn === 'Y') {
        item.pushYn = 'N'
      } else {
        item.pushYn = 'Y'
      }
    },
    onSaveInsurePush () {
      this.emitter.emit('Loading', true)
      const confirmMessage = '저장하시겠습니까?'
      if (confirm(confirmMessage)) {
        const originData = { ...this.insurePushList }
        const pushItemList = []
        for (const vendorCode in originData) {
          const targetDataList = originData[vendorCode].children

          targetDataList.forEach(item => {
            pushItemList.push({
              vendorCode: item.vendorCode,
              pushTypeCode: item.pushTypeCode,
              pushYn: item.pushYn
            })
          })
        }

        const params = {
          siteId: this.siteId,
          memId: this.memId,
          pushItemList
        }

        saveInsurePush(params).then(res => {
          if (res.resultCode === '0') {
            alert('저장이 완료되었습니다.')
          } else {
            alert(res.resultMessage)
          }
          this.emitter.emit('Loading', false)
        }).catch(e => {
          alert(e)
          this.emitter.emit('Loading', false)
        })
      }
    },
    biModalsOpen () {
      this.biModals.active = true
    },
    biModalsClose () {
      this.biModals.active = false
    },
    passingModalOpen () {
      this.passingModal.active = true
    },
    passingModalClose () {
      this.passingModal.active = false
    },
    modalClose (value) {
      this.msgOpen = value
    },
    cashModalClose () {
      this.cashModal.model.amt = 0
      this.cashModal.active = false
    },
    modalControl (memId) {
      this.getUserDetail(memId)
      this.modal.target = memId
      this.modal.active = !this.modal.active
    },
    writeOpen () {
      msgOpen = true
    },
    historyOpen () {
      // window.open("/cms/cash/history", "cashHistory", "top=100,left=150,scrollbars=no,resizable=no,width=565,height=701,status=no");
    },
    async getUserDetail () {
      console.log('1', this.reqData)
      const res = await memberDetail(this.reqData)
      console.log('user detail : ', res)
      this.userData = res.data.member
      this.userBonus = { ...res.data.bonusSettingInfo }
      this.userBonus.bonusLimit = this.userBonus.bonusLimit.toString()
      this.currentPassword = JSON.parse(JSON.stringify(this.userData.memPass))

      if (this.userData.lastLoginDt) {
        this.userData.lastLoginDt = replaceDateT(this.userData.lastLoginDt)
      }
      this.userData.redDt = replaceDateT(this.userData.redDt)
      this.userData.totalWlAmt = numberWithCommas(this.userData.totalWlAmt)
      this.userData.totalLoginCount = numberWithCommas(this.userData.totalLoginCount)
      this.userData.totalCashOutAmt = numberWithCommas(this.userData.totalCashOutAmt)
      this.userData.totalChangePointAmt = numberWithCommas(this.userData.totalChangePointAmt)
      this.userData.totalCashInAmt = numberWithCommas(this.userData.totalCashInAmt)
      this.userData.totalBonusAmt = numberWithCommas(this.userData.totalBonusAmt)

      this.isBlankSpinCasinoRate = !!this.userData.blankSpinCasinoRate
      this.isBlankSpinSlotRate = !!this.userData.blankSpinSlotRate

      for (const item in this.userData) {
        if (item.indexOf('Yn') !== -1 && item !== 'memberCommissionRate') {
          if (this.userData[item] === 'Y') {
            this.userData[item] = true
          } else {
            this.userData[item] = false
          }
        }
      }

      const partnerFlow = res.data.partnerFlow
      const memberFlow = await this.jsonSort(res.data.memberFlow, 'order', 'desc')
      this.memberFlow = memberFlow.splice(1, memberFlow.length - 2)

      this.rateUpdate.siteId = res.data.member.siteId
      this.rateUpdate.memId = res.data.member.memId

      this.memRollingInfo = res.data.memRollingInfo
      this.memRollingInfo.forEach(item => {
        if (item.detailSetYn === 'Y') {
          item.isOpenDetail = false
        } else {
          item.errorP = false
          item.errorR = false
        }
      })
    },
    getUserRate () {
      return memberRate(this.reqData)
    },
    getGameCode () {
      return getGameCodes('kplay', '', '', this.siteInfo.siteId)
    },
    setRateLoop (maxRate) {
      return maxRate / 0.05
    },
    getMemLevel () {
      this.userData.memLevel
    },
    async pageSeach () {
      if (this.reqData.memId) {
        await this.getUserDetail()
        await this.getSubMemberInfo()
        await this.setGameList()
        await this.getBank()

        this.gameList = this.$store.getters.gameList
        this.scontentSetting()
      } else {
        this.userData = null
      }
    },
    async setMemStatus () {
      const reqData = {
        memId: this.userData.memId,
        memStatus: this.userData.memStatus
      }
      try {
        const res = await memStatusChange(reqData)
        if (res.resultCode !== '0') {
          alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
        } else {
          alert('회원상태 변경완료')
        }
      } catch (e) {
        alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
      }
    },
    setRateListDefault () {
      console.log('setRateListDefault : ', this.gameListDefault, this.rateModel)
      for (const loop of this.gameListDefault) {
        for (const item of loop.child) {
          const model = lodash.cloneDeep(this.rateModel)
          model.gameType = item.code
          model.gameName = item.codeName
          model.groupCode = item.groupCode
          model.loop = Number(model.maxPointRate) / this.rateIncrease
          this.rateList.push(model)
        }
      }
      this.combineUserRate()
    },
    setInsuredListDefault () {
      // console.log(this.gameListDefault);
      for (const loop of this.gameListDefault) {
        for (const item of loop.child) {
          const model = lodash.cloneDeep(this.rateModel)
          model.gameType = item.code
          model.gameName = item.codeName
          model.groupCode = item.groupCode
          model.loop = 100 / this.rateIncrease
          model.maxRate = 100
          this.insuredRateList.push(model)
        }
      }
      // this.combineUserRate();
    },
    combineUserRate () {
      console.log(this.userData.memberCommissionRate)
      const rateList = lodash.cloneDeep(this.rateList)
      const newArray = []
      for (const loop of this.userData.memberCommissionRate) {
        rateList.forEach((target, index, arr) => {
          if (target.gameType === loop.gameType) {
            target.pointRate = loop.pointRate
            target.loseRate = loop.loseRate
            target.maxLoseRate = loop.maxLoseRate
            target.minLoseRate = loop.minLoseRate
            target.maxRate = loop.maxRate
            target.maxPointRate = loop.maxPointRate
            target.minPointRate = loop.minPointRate
            target.loop = Math.round(Number(target.maxRate) / this.rateIncrease)
            if (loop.betYn === 'Y') {
              target.betYn = true
            } else {
              target.betYn = false
            }
            if (loop.useYn === 'Y') {
              target.useYn = true
            } else {
              target.useYn = false
            }
            return false
          }
        })
      }
      this.originRate = rateList
      console.log('tt : ', rateList)
      this.rateList = rateList
    },
    scontentSetting () {
      const scontent = document.querySelectorAll('.s-content-wrap')

      if (scontent.length > 0) {
        const parent = scontent[0].parentElement
        const title = scontent[0].previousElementSibling
        const pHeight = parent.clientHeight
        const tHeight = title.offsetHeight
        const targetHeight = pHeight - (tHeight + 5)
        this.scontentHeight = targetHeight
      }

      // console.log(scontent)
    }
  },
  watch: {
    isBlankSpinCasinoRate () {
      if (this.isBlankSpinCasinoRate && !this.userData.blankSpinCasinoRate) {
        this.userData.blankSpinCasinoRate = 1
      } else if (!this.isBlankSpinCasinoRate) {
        this.userData.blankSpinCasinoRate = 0
      }
    },
    isBlankSpinSlotRate () {
      if (this.isBlankSpinSlotRate && !this.userData.blankSpinSlotRate) {
        this.userData.blankSpinSlotRate = 1
      } else if (!this.isBlankSpinSlotRate) {
        this.userData.blankSpinSlotRate = 0
      }
    },
    'userData.blankSpinCasinoRate' () {
      this.userData.blankSpinCasinoRate = Math.round(this.userData.blankSpinCasinoRate)
      if (this.userData.blankSpinCasinoRate > 100) {
        this.userData.blankSpinCasinoRate = 100
      }
      if (this.userData.blankSpinCasinoRate) {
        this.isBlankSpinCasinoRate = true
      } else {
        this.isBlankSpinCasinoRate = false
      }
    },
    'userData.blankSpinSlotRate' () {
      this.userData.blankSpinSlotRate = Math.round(this.userData.blankSpinSlotRate)
      if (this.userData.blankSpinSlotRate > 100) {
        this.userData.blankSpinSlotRate = 100
      }
      if (this.userData.blankSpinSlotRate) {
        this.isBlankSpinSlotRate = true
      } else {
        this.isBlankSpinSlotRate = false
      }
    },
    'userData.baseInsureBetAmt' () {
      const parts = this.userData.baseInsureBetAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.userData.baseInsureBetAmt = n
    },
    'userBonus.bonusLimit' () {
      if (this.userBonus.bonusLimit) {
        console.log(this.userBonus.bonusLimit)
        const parts = this.userBonus.bonusLimit.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.userBonus.bonusLimit = n
      }
    },
    gameList: {
      handler (value) {
        this.setRateListDefault(value)
        this.setInsuredListDefault()
      }
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    await this.getLevels()
    await this.getUserDetail()
    // await this.getSubMemberInfo();
    await this.setGameList()
    await this.getBank()

    this.gameList = this.$store.getters.gameList
    this.scontentSetting()
  }
}
</script>

<style scoped>
.left {text-align: left !important;}
.pw {color: #585963;font-size: 9px;}
.userName {color: #876a97;font-size: 12px;}
.userName em {color: #ff7e00;}
.mt-20 {margin-top: -20px;}
.w50 { width: 50px; }
.w80 { width: 80px !important; }
.bg {background: #f9f9f9 !important;width: 25%;}
.tab-item:hover { background: #eb7a3f; color: #fff;}
.tab-item.active {background: #eb7a3f;}
.levelbox > span {margin: 0;}
.onlyNum {
  position: relative;
  max-width: 190px;
  height: 30px;
  margin: 20px auto;
}
.page-content .content .input#newCashamt {margin-top: 0;}
#newCashamt {
  width: 100%;
  max-width: 190px;
  border: 1px solid transparent;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: transparent;
  position: relative;
  z-index: 1;
}
#newCashamt::selection{
  background:none;
}
#newCashamt+label {
  font-size: 14px;
  background: #fff;
  color: #000;
  font-weight: 500;
  border: 1px solid #18698b;
  border-radius: 3px;
  height: 30px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 100%;
  max-width: 190px;
  position: absolute;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.allwrap {position: relative;}
.allwrap > .wrapper.fx-col {width: 100%;}
.allwrap .user-wrapper-outer > .middle-wrap > table {width: auto;}
.allwrap .user-wrapper-outer#tab11 > .middle-wrap > table {width: 100%;}
#tab11 th {font-size: 14px;}
#tab11 td { text-align: center; }
#tab11 td input {border: 1px solid #000; width: 110px; padding: 5px; box-sizing: border-box;}
.fx {display: flex !important;}
.mr10 {margin-right: 10px;}
.ml20 {margin-left: 20px;border-left: 1px solid #b7b7b7;padding-left: 10px;}
.btn-grey {
  width: 70px;
  height: 22px;
  padding: 0 12px;
  color: #fff;
  cursor: pointer;
}
.mb50 {margin-bottom: 50px;}
.mb20 {margin-bottom: 20px;}
.scrolltTop {display: flex;align-items: center;justify-content: center;background: #000;color: #fff;width: 80px;height: 45px;float: right;}
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
.seletAndDelete {
  display: flex;
  align-items: center;
}
.msg-all-sel {
  margin-right: 4px !important;
  height: 30px;
  box-sizing: border-box;
}
#siteId {
  margin-right: 4px;
}
.btn-state-change.cancle {
  margin-right: 4px;
}
.user-detail-popup {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  width: fit-content;
  margin: 0;
  box-sizing: border-box;
  background: #353535;
}
.user-detail-popup select {
  border: 1px solid #e1e0e0;
  height: 30px;
}
.main-contents-wrapper > div {margin: 0;}
.mainTable th {background: #a4a4a4 !important;}
.wrapper-title > .subWrap > span {font-size: initial;}
.user-memo-wrap textarea {border: 1px solid #e1e0e0; height: 354px;}
.user-memo-wrap .content {margin: 20px 22px;}
.user-wrapper-outer > .middle-wrap {display: block;}
.user-wrapper-outer > .middle-wrap .content > .title {width: 250px;color: #353535;}
.wrapper-title {width: auto;height: 45px;border-bottom: 1px solid #e1e0e0;padding: 0 22px;}
.wrapper-title > span {font-size: 14px;}
.wrapper-title .btn-save {
  padding: 0 12px;
}
.content .btn-save {
  padding: 4px 7px;
  margin-left: 5px;
}
.button-wrap {
  display: flex;
  gap: 6px;
}
.button-wrap > button {
   flex: 1;
   height: 28px;
   box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
   box-sizing: border-box;
   border: solid 1px #616161;
   background-color: #ffebeb;
   color: #3d3d3d;
}
.userInfo-select {
  width: 55px;
  border: 1px solid #e1e0e0;
  font-weight: 500;
  padding: 2px 5px;
  height: 22px;
}
.s-content > .content {
  display: flex;
  height: 36px;
  margin-left: auto;
}
.s-content > .content > input {
  width: 50px;
}
.modal-item {
  width: 350px;
  margin: 0 auto;
  position: relative;
  top: 20%;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #6f7172;
  overflow: hidden;
}
.vspassing {font-size: 10px;color: #ff4e00;width: calc(100% - 35px);}
.modal-item.wh {background: #fff;}
.wh .modal-title {display: flex;padding-top: 20px;}
.wh .mwrap {border: solid 1px #d5d5d5;border-bottom: 0;box-sizing: border-box;width: 90%;margin: 30px auto 0;}
.wh .modal-close {color: #000;}
.wh .title {color: #000;display: flex;align-items: center;height: 45px;background: #F9F9F9;border-bottom: solid 1px #d5d5d5;padding-left: 20px;box-sizing: border-box;}
.wh .mainTable {width: 100%;}
.wh .mainTable td {padding: 10px 15px;}
.modal-close {top: -10px;right: 10px;color: #000;}
.sub-title {border-bottom: 2px solid #e1534e;font-weight: normal;}
.modal-item .fx-item-auto {text-align: center;margin: 20px 0;}
.modal-item .fx-item-auto input {width: 200px;color: #a4a4a4;}
.btn-wrap {margin-bottom: 25px;}
.btn-wrap button {width: 200px;height: 35px;font-size: 18px;}
.table-wrap {
  overflow-y: scroll;
  max-height: 120px;
  margin: 15px 22px;
}
.mainTable th {
  height: 40px;
  /* background: var(--main); */
  background: #353535;
  color: #fff;
  vertical-align: middle;
}

.supComp-list-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
}
[class*="compColor"] {
  font-weight: bold;
}
button[class*="compColor"]:after {
  content: "▶";
  display: inline;
  margin-left: 5px;
}
[class*="compColor"]:last-child:after {
  content: "";
}
.compColor-target {
  color: #d93832;
}
.s-content-wrap {
  max-height: initial;
}
.s-content:nth-child(4n+1) {padding: 0;border: 0;}
.s-content {
  /*flex-basis: 74px;
  max-width: 74px;
  min-height: 30px;
  font-size: 14px;
  border: 0;*/
  flex-basis: calc(25% - 18px);
  font-size: 14px;
  border: 0;
  display: flex;
  border-left: 1px solid #e1e0e0;
  padding-left: 10px;
  margin: 0;
}
.s-content > .title {
  overflow: hidden;
  text-overflow: ellipsis;
  background: none;
  color: #353535;
}

.user-wrapper-outer {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  border: 0;
  border: 3px solid #353535;
  padding: 0;
}
.user-wrapper-outer .wrapper-contents {
  flex: 0 1 calc(100% - 42px);
  gap: 5px 10px;
  margin: 15px 22px;
}
.rate-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
}

.rate-item-wrap {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 1 23.1%;
}
.rate-item-wrap .item-title {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  width: 78px;
  flex: 1 0 auto;
  word-break: keep-all;
  /* white-space: nowrap; */
}
.rate-item-container .item-title > span {
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.rate-item-container .item-line {
  width: 1px;
  background: #c5c5c5;
  height: 25px;
}
.rate-item-container .info-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  padding: 5px 8px;
  font-weight: 700;
  border-radius: 3px;
  width: 46px;
  font-size: 1em;
  margin-right: 0;
  border: 1px solid #c5c5c5;
  user-select: none;
}

.rate-item-container .item-line:last-child {
  display: none;
}
.batchRate {
  width: 70px;
  text-align: center;
}
.rollLoss {align-items: center;}
.rollLoss > span {margin: 0 10px 0 15px;}
.bimodal{color: #dbdbdb;}
.bimodal .wrapper-title span{}
.bimodal table {width: 90%; margin: 0 auto; box-sizing: border-box;color: #000; }
.bimodal table tr > td {padding: 10px 0; }
.bimodal table tr > td ul { display: flex; justify-content: space-between; gap: 10px; padding: 0px 5px; box-sizing: border-box;}
.bimodal table tr > td ul li {white-space: nowrap;}
.w284 {width: 284px;}
.allwrap {
  max-width: 1600px;
}
.allwrap > div {
  display: flex;
  gap: 20px;
}
.allwrap > div.notflex {
  display: block;
}
.allwrap > div.center {
  justify-content: center;
}
.content_wrap{
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  width: calc(50% - 20px);
  padding: 13px;
  background-color: #fff;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #b1b1b1;
}
.content_wrap.all {width: 100%;flex-direction: row;}
.content_wrap.all > div:nth-child(1) {width: 50%;}
.content_wrap.all > div:nth-child(2) {width: 32%;}
.content_wrap.all > div:nth-child(3) {width: 18%;}
.content_wrap.all > div {display: flex;flex-direction: column;gap: 20px;}
.part-wrap {display: flex;gap: 20px;}
.part-box {width: 100%;}
.part-box:last-child {display: flex;flex-direction: column;flex-wrap: wrap;gap: 23px;}
.part-in {}
.content_wrap.w100w{
  width: 100%;
}
.content_wrap.w50w{
  width: 50%;
}
.content_wrap table {
  font-size: 12px;
  color: #585963;
  width: 100%;
}
.content_wrap table select {
  border-color: #c4c4c4;
}
.content_wrap table thead{
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
  border: solid 1px #b1b1b1;
  background-color: #f8f8f8;
  font-size: 13px;
  color: #3d3d3d;
}
.content_wrap table th {
  padding: 5px 14px;
  text-align: center;
  box-sizing: border-box;
  height: 30px;
}
.content_wrap table thead th.flexEnd{
  display: flex;
  justify-content: flex-end;
}
.content_wrap table thead th:nth-child(2) .saveBtnWrap {
  display: flex;
  gap: 10px;
}
.saveBtnWrap .user-btn-bg {
  padding: 3px 10px;
}
.user-btn-bg.chasnge-partner {
  color: #fff;
  background: #82a6a4;
  border: 1px solid #82a6a4;
}
.user-btn-bg.chasnge-partner:hover {
  color: #fff;
  background: #82a6a4;
}
.content_wrap table td {
  padding: 0 14px;
  border: 1px solid #b1b1b1;
  background-color: #fff;
  box-sizing: border-box;
  height: 30px;
}
input[type="text"], input[type="password"], input[type="number"] {
  width: 120px;
  border-color: #c4c4c4;
}
select.w_half {
  width: 90px !important;
}
select.w_full {
  width: 186px !important;
}
.w_half:nth-child(1), .w_full:nth-child(1) {
  margin-right: 5px;
}
.setTab {
  display: flex;
}
.setTab li {
  cursor: pointer;
  width: 224px;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: #bebebe;
  border-radius: 3px 3px 0 0;
  border: solid 1px #d5d5d5;
  background-color: #fff;
  box-sizing: border-box ;
}
.setTab li.active {
  color: #000;
  background-color: #f9f9f9;
  border-top: 3px solid #eb7a3f;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.16);
}
.gameSetWrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: #fff;
}
.gameSet {
  width: 20%;
  display: flex;
}
.gameSet > * {
  font-size: 12px;
  color: #585963;
  white-space: nowrap;
  padding: 10px 13px;
  box-sizing: border-box;
  border: 1px solid #b1b1b1;
  border-top: 0;
  background: #fff;
}
.gameSet > *:nth-child(1) {
  width: 70%;
  display: flex;
  align-items: center;
}
.gameSet > *:nth-child(2) {
  width: 30%;
  border-left: 0;
  border-right: 0;
}
.gameSet:nth-child(5n) > *:nth-child(2) {
  border-right: 1px solid #d5d5d5;
}
.gameSet:last-child > *:nth-child(2) {
  position: relative;
}
.gameSet:last-child > *:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  right:-1px;
  height: 36px;
  width: 1px;
  display: inline-block;
  background: #d5d5d5;
}
.rateSetWrap {
  display: flex;
  width: 100%;
  background: #fff;
}
.rateSetWrap .rateL {
  width: 33%;
}
.rateSetWrap .rateR {
  width: 67%;
}
.rateL.w20w {
  width: 20%;
}
.rateR.w80w {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.rateL > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
}
.rateL > .btn-layout {
  padding: 3px 7px;
  color: #fff;
}
.btn-range {
  background: #6f7172;
}
.btn-min {
  border-radius: 3px 0 0 3px;
  margin-right: 5px;
}
.btn-max {
  border-radius: 0 3px 3px 0;
  margin-left: 5px;
}
.btn-min i {margin-left: 5px;color: #a4bbfd;}
.btn-max i {margin-left: 5px;color: #ffa06e;}
.btn-apply {
  background: #eb7a3f;
  border-radius: 3px;
}
.rate-content {
  border: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.rateR.w80w .rate-content{
  width: 50%;
}
.rateR.w80w .rate-content.w33w{
  width: 33.33%;
}
.rate-content > .content {
  display: flex;
  font-size: 14px;
  height: 36px;
}
.rate-content > div {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  width: 33.33%;
  padding: 5px 12px;
  font-size: 14px;
  height: 52px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.rate-content .rollcount {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}
.rate-content > div:nth-child(2) {
  border-left:0;
  border-right:0;
  justify-content: center;
}
.rate-content > div:nth-child(3) {
  justify-content: center;
}
.minibtn {width: 66.66% !important;margin: 0 auto;display: flex;align-items: center;justify-content: center;}
.minibox {z-index: 100;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);width: auto !important;height: auto !important;padding: 40px 40px 30px !important;background: #fff;flex-direction: column;gap: 15px;box-sizing: border-box;border: 1px solid #d5d5d5;white-space: nowrap;display: flex;align-items: center;}
.minibox table {height: 100%;}
.minibox td > div {display: flex;align-items: center;gap: 10px;}
.minibox .close {position: absolute;top: 10px;right: 15px;font-size: 20px;cursor: pointer;}
.minibox button.save, button.save {padding: 10px 40px;font-size: 16px;margin-top: 10px;}
.minibox .error {border: 1px #944747 solid;}

.rollcount > input[type="number"] {
  width: 50px;
  text-align: center;
  border-top: 0;
  border-bottom: 0;
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}
.center button {
  margin: 50px 0 70px;
  font-size: 18px;
  width: 160px;
  height: 40px;
  background: #eb7a3f;
  border-radius: 3px;
  color: #fff;
  border: 0;
  font-weight: 600;
}
textarea {
  width: 100%;
  min-height: 99%;
  resize: none;
  box-sizing: border-box;
  font-size: 15px;
}
.user-btn-bg {
  background: #252930;
  border-radius: 4px;
  font-size: 12px;
  transition: .3s;
}
.user-btn-bg:hover {
  background: #fff;
  color: #000;
}
.inputBtnWrap {
  display: flex;
  gap: 5px;
  align-items: center;
}
.inputBtnWrap > div {display: flex;gap: 5px;align-items: center;}
.inputBtnWrap > div:first-child {margin-right: 10px;}
.inputBtnWrap span {
  /*display: inline-block;
  width: 16px;
  font-size: 14px;*/
}
.btn-layout {
  border-radius: 3px;
  padding: 3px 11px;
}
.btn-red{
  background: #82a6a4;
}
.btn-point{
  background: #eb7a3f;
}
.widthSet{
  display: flex;
}
.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
  padding: 0 5px;
}
input[type="number"].w50 {
  width: 50px;
}
.btn-rate {
  position: absolute;
  top: 0;
  right: 0;
}
.ratewrap .content_wrap {
  position: relative;
  padding-top:50px;
}
.ratewrap .btn-rate{
  top: 10px;
  right: 12px;
}
.btnwrap{
  display: flex;
  justify-content: center;
}
.btn-inout {width: auto;height: auto;padding: 3px 6px;cursor: pointer;}
</style>
